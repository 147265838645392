import React from "react";
import AnnouncementBar from "../../../components/AnnouncementBar/AnnouncementBar";
import AddUserIndex from "../../../components/SidetabPages/SystemAccess/AddEditForm";

const AddUser = () => {
  return (
    <>
      <AnnouncementBar />
      <AddUserIndex />
    </>
  );
};
export default AddUser;
