import React from "react";
import ForgetPassForm from "../../components/AccountsForm/ForgetPassword/ForgetPassForm";

const ForgetPassword = () => {
  return (
    <>
      <ForgetPassForm />
    </>
  );
};
export default ForgetPassword;
