import React from 'react'

export const FooterFacebook = () => {
  return (
    <>
     <div className="copyright-facebook-landing">
        <p className='m-0'>© 2023 American Tax Settlement, LLC | All Rights Reserved</p>
     </div>
    </>
  )
}
