/* >>>>>>>>>>>>>> EXPORT_ADMIN_TRANSACTIONS >>>>>>>>>>>>>>>*/

export const EXPORT_ADMIN_TRANSACTIONS = "EXPORT_ADMIN_TRANSACTIONS";
export const EXPORT_ADMIN_TRANSACTIONS_SUCCESS =
  "EXPORT_ADMIN_TRANSACTIONS_SUCCESS";
export const EXPORT_ADMIN_TRANSACTIONS_FAIL = "EXPORT_ADMIN_TRANSACTIONS_FAIL";

/* >>>>>>>>>>>>>> EXPORT_BOOKING_DATA >>>>>>>>>>>>>>>*/

export const EXPORT_BOOKING_DATA = "EXPORT_BOOKING_DATA";
export const EXPORT_BOOKING_DATA_SUCCESS = "EXPORT_BOOKING_DATA_SUCCESS";
export const EXPORT_BOOKING_DATA_FAIL = "EXPORT_BOOKING_DATA_FAIL";

/* >>>>>>>>>>>>>> EXPORT_PROVIDER_WITHDRAWL_REQUESTS >>>>>>>>>>>>>>>*/
export const EXPORT_PROVIDER_WITHDRAWL_REQUESTS =
  "EXPORT_PROVIDER_WITHDRAWL_REQUESTS";
export const EXPORT_PROVIDER_WITHDRAWL_REQUESTS_SUCCESS =
  "EXPORT_PROVIDER_WITHDRAWL_REQUESTS_SUCCESS";
export const EXPORT_PROVIDER_WITHDRAWL_REQUESTS_FAIL =
  "EXPORT_PROVIDER_WITHDRAWL_REQUESTS_FAIL";
