
// ================= DASHBOARD_DETAILS =================== //
export const DASHBOARD_DETAILS = "DASHBOARD_DETAILS";
export const DASHBOARD_DETAILS_SUCCESS ="DASHBOARD_DETAILS_SUCCESS";
export const DASHBOARD_DETAILS_FAIL = "DASHBOARD_DETAILS_FAIL";


// ================= BOOKINGS_LIST =================== //
export const BOOKINGS_LIST = "BOOKINGS_LIST";
export const BOOKINGS_LIST_SUCCESS ="BOOKINGS_LIST_SUCCESS";
export const BOOKINGS_LIST_FAIL = "BOOKINGS_LIST_FAIL";


// ================= BOOKING_DETAIL =================== //
export const BOOKING_DETAIL = "BOOKING_DETAIL";
export const BOOKING_DETAIL_SUCCESS ="BOOKING_DETAIL_SUCCESS";
export const BOOKING_DETAIL_FAIL = "BOOKING_DETAIL_FAIL";

// ================= UPDATE BOOKING STATUS =================== //
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const UPDATE_BOOKING_STATUS_SUCCESS ="UPDATE_BOOKING_STATUS_SUCCESS";
export const UPDATE_BOOKING_STATUS_FAIL = "UPDATE_BOOKING_STATUS_FAIL";


// ================= ADMIN_TRANSACTION_LIST =================== //
export const ADMIN_TRANSACTION_LIST = "ADMIN_TRANSACTION_LIST";
export const ADMIN_TRANSACTION_LIST_SUCCESS ="ADMIN_TRANSACTION_LIST_SUCCESS";
export const ADMIN_TRANSACTION_LIST_FAIL = "ADMIN_TRANSACTION_LIST_FAIL";


// ================= PROVIDER_WITHDRAWL_LIST =================== //
export const PROVIDER_WITHDRAWL_LIST = "PROVIDER_WITHDRAWL_LIST";
export const PROVIDER_WITHDRAWL_LIST_SUCCESS ="PROVIDER_WITHDRAWL_LIST_SUCCESS";
export const PROVIDER_WITHDRAWL_LIST_FAIL = "PROVIDER_WITHDRAWL_LIST_FAIL";


// ================= PROCESS_WITHDRAWAL =================== //
export const PROCESS_WITHDRAWAL = "PROCESS_WITHDRAWAL";
export const PROCESS_WITHDRAWAL_SUCCESS ="PROCESS_WITHDRAWAL_SUCCESS";
export const PROCESS_WITHDRAWAL_FAIL = "PROCESS_WITHDRAWAL_FAIL";


// ================= CANCEL_WITHDRAWAL =================== //
export const CANCEL_WITHDRAWAL = "CANCEL_WITHDRAWAL";
export const CANCEL_WITHDRAWAL_SUCCESS ="CANCEL_WITHDRAWAL_SUCCESS";
export const CANCEL_WITHDRAWAL_FAIL = "CANCEL_WITHDRAWAL_FAIL";


// ================= CUSTOMER_REPORT_GRAPH =================== //
export const CUSTOMER_REPORT_GRAPH = "CUSTOMER_REPORT_GRAPH";
export const CUSTOMER_REPORT_GRAPH_SUCCESS ="CUSTOMER_REPORT_GRAPH_SUCCESS";
export const CUSTOMER_REPORT_GRAPH_FAIL = "CUSTOMER_REPORT_GRAPH_FAIL";


// ================= BOOKING_REPORT_GRAPH =================== //
export const BOOKING_REPORT_GRAPH = "BOOKING_REPORT_GRAPH";
export const BOOKING_REPORT_GRAPH_SUCCESS ="BOOKING_REPORT_GRAPH_SUCCESS";
export const BOOKING_REPORT_GRAPH_FAIL = "BOOKING_REPORT_GRAPH_FAIL";