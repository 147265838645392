
//vehicle make
export const VEHICLE_MAKE_LIST = "VEHICLE_MAKE_LIST";
export const VEHICLE_MAKE_LIST_SUCCESS = "VEHICLE_MAKE_LIST_SUCCESS";
export const VEHICLE_MAKE_LIST_FAIL = "VEHICLE_MAKE_LIST_FAIL";

export const ADD_VEHICLE_MAKE = "ADD_VEHICLE_MAKE";
export const ADD_VEHICLE_MAKE_SUCCESS = "ADD_VEHICLE_MAKE_SUCCESS";
export const ADD_VEHICLE_MAKE_FAIL = "ADD_VEHICLE_MAKE_FAIL";

export const UPDATE_VEHICLE_MAKE = "UPDATE_VEHICLE_MAKE";
export const UPDATE_VEHICLE_MAKE_SUCCESS = "UPDATE_VEHICLE_MAKE_SUCCESS";
export const UPDATE_VEHICLE_MAKE_FAIL = "UPDATE_VEHICLE_MAKE_FAIL";

export const GET_VEHICLE_MAKE_DETAILS = "GET_VEHICLE_MAKE_DETAILS";
export const GET_VEHICLE_MAKE_DETAILS_SUCCESS = "GET_VEHICLE_MAKE_DETAILS_SUCCESS";
export const GET_VEHICLE_MAKE_DETAILS_FAIL = "GET_VEHICLE_MAKE_DETAILS_FAIL";

export const DELETE_VEHICLE_MAKE = "DELETE_VEHICLE_MAKE";
export const DELETE_VEHICLE_MAKE_SUCCESS = "DELETE_VEHICLE_MAKE_SUCCESS";
export const DELETE_VEHICLE_MAKE_FAIL = "DELETE_VEHICLE_MAKE_FAIL";

//vehicle model
export const VEHICLE_MODEL_LIST = "VEHICLE_MODEL_LIST";
export const VEHICLE_MODEL_LIST_SUCCESS = "VEHICLE_MODEL_LIST_SUCCESS";
export const VEHICLE_MODEL_LIST_FAIL = "VEHICLE_MODEL_LIST_FAIL";

export const ADD_VEHICLE_MODEL = "ADD_VEHICLE_MODEL";
export const ADD_VEHICLE_MODEL_SUCCESS = "ADD_VEHICLE_MODEL_SUCCESS";
export const ADD_VEHICLE_MODEL_FAIL = "ADD_VEHICLE_MODEL_FAIL";

export const UPDATE_VEHICLE_MODEL = "UPDATE_VEHICLE_MODEL";
export const UPDATE_VEHICLE_MODEL_SUCCESS = "UPDATE_VEHICLE_MODEL_SUCCESS";
export const UPDATE_VEHICLE_MODEL_FAIL = "UPDATE_VEHICLE_MODEL_FAIL";

export const GET_VEHICLE_MODEL_DETAILS = "GET_VEHICLE_MODEL_DETAILS";
export const GET_VEHICLE_MODEL_DETAILS_SUCCESS = "GET_VEHICLE_MODEL_DETAILS_SUCCESS";
export const GET_VEHICLE_MODEL_DETAILS_FAIL = "GET_VEHICLE_MODEL_DETAILS_FAIL";

export const DELETE_VEHICLE_MODEL = "DELETE_VEHICLE_MODEL";
export const DELETE_VEHICLE_MODEL_SUCCESS = "DELETE_VEHICLE_MODEL_SUCCESS";
export const DELETE_VEHICLE_MODEL_FAIL = "DELETE_VEHICLE_MODEL_FAIL";


//service
export const SERVICE_LIST = "SERVICE_LIST";
export const SERVICE_LIST_SUCCESS = "SERVICE_LIST_SUCCESS";
export const SERVICE_LIST_FAIL = "SERVICE_LIST_FAIL";

export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";

export const GET_SERVICE_DETAILS = "GET_SERVICE_DETAILS";
export const GET_SERVICE_DETAILS_SUCCESS = "GET_SERVICE_DETAILS_SUCCESS";
export const GET_SERVICE_DETAILS_FAIL = "GET_SERVICE_DETAILS_FAIL";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";



//service add on
export const SERVICE_ADDON_LIST = "SERVICE_ADDON_LIST";
export const SERVICE_ADDON_LIST_SUCCESS = "SERVICE_ADDON_LIST_SUCCESS";
export const SERVICE_ADDON_LIST_FAIL = "SERVICE_ADDON_LIST_FAIL";

export const ADD_SERVICE_ADDON = "ADD_SERVICE_ADDON";
export const ADD_SERVICE_ADDON_SUCCESS = "ADD_SERVICE_ADDON_SUCCESS";
export const ADD_SERVICE_ADDON_FAIL = "ADD_SERVICE_ADDON_FAIL";

export const UPDATE_SERVICE_ADDON = "UPDATE_SERVICE_ADDON";
export const UPDATE_SERVICE_ADDON_SUCCESS = "UPDATE_SERVICE_ADDON_SUCCESS";
export const UPDATE_SERVICE_ADDON_FAIL = "UPDATE_SERVICE_ADDON_FAIL";

export const GET_SERVICE_ADDON_DETAILS = "GET_SERVICE_ADDON_DETAILS";
export const GET_SERVICE_ADDON_DETAILS_SUCCESS = "GET_SERVICE_ADDON_DETAILS_SUCCESS";
export const GET_SERVICE_ADDON_DETAILS_FAIL = "GET_SERVICE_ADDON_DETAILS_FAIL";

export const DELETE_SERVICE_ADDON = "DELETE_SERVICE_ADDON";
export const DELETE_SERVICE_ADDON_SUCCESS = "DELETE_SERVICE_ADDON_SUCCESS";
export const DELETE_SERVICE_ADDON_FAIL = "DELETE_SERVICE_ADDON_FAIL";