export const SERVICE_PROVIDER_LIST = "SERVICE_PROVIDER_LIST";
export const SERVICE_PROVIDER_LIST_SUCCESS = "SERVICE_PROVIDER_LIST_SUCCESS";
export const SERVICE_PROVIDER_LIST_FAIL = "SERVICE_PROVIDER_LIST_FAIL";

/* >>>>>>>>>>>>>> ADD_SERVICE_PROVIDER >>>>>>>>>>>>>>>*/

export const ADD_SERVICE_PROVIDER = "ADD_SERVICE_PROVIDER";
export const ADD_SERVICE_PROVIDER_SUCCESS = "ADD_SERVICE_PROVIDER_SUCCESS";
export const ADD_SERVICE_PROVIDER_FAIL = "ADD_SERVICE_PROVIDER_FAIL";

// /* >>>>>>>>>>>>>> EDIT_SERVICE_PROVIDER >>>>>>>>>>>>>>>*/

export const EDIT_SERVICE_PROVIDER = "EDIT_SERVICE_PROVIDER";
export const EDIT_SERVICE_PROVIDER_SUCCESS = "EDIT_SERVICE_PROVIDER_SUCCESS";
export const EDIT_SERVICE_PROVIDER_FAIL = "EDIT_SERVICE_PROVIDER_FAIL";

// /* >>>>>>>>>>>UPDATE_SERVICE_PROVIDER <<<<<<<<<<<<<<< */
export const UPDATE_SERVICE_PROVIDER = "UPDATE_SERVICE_PROVIDER";
export const UPDATE_SERVICE_PROVIDER_SUCCESS =
  "UPDATE_SERVICE_PROVIDER_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_FAIL = "UPDATE_SERVICE_PROVIDER_FAIL";

// /* >>>>>>>>>>>BLOCK_UNBLOCK_PROVIDER <<<<<<<<<<<<<<< */
export const BLOCK_UNBLOCK_PROVIDER = "BLOCK_UNBLOCK_PROVIDER";
export const BLOCK_UNBLOCK_PROVIDER_SUCCESS = "BLOCK_UNBLOCK_PROVIDER_SUCCESS";
export const BLOCK_UNBLOCK_PROVIDER_FAIL = "BLOCK_UNBLOCK_PROVIDER_FAIL";

/* >>>>>>>>>>>>>> DELETE_SERVICE_PROVIDER >>>>>>>>>>>>>>>*/
export const DELETE_SERVICE_PROVIDER = "DELETE_SERVICE_PROVIDER";
export const DELETE_SERVICE_PROVIDER_SUCCESS =
  "DELETE_SERVICE_PROVIDER_SUCCESS";
export const DELETE_SERVICE_PROVIDER_FAIL = "DELETE_SERVICE_PROVIDER_FAIL";


// /* >>>>>>>>>>>UPDATE_SERVICE_PROVIDER_STATUS <<<<<<<<<<<<<<< */
export const UPDATE_SERVICE_PROVIDER_STATUS = "UPDATE_SERVICE_PROVIDER_STATUS";
export const UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS =
  "UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_STATUS_FAIL = "UPDATE_SERVICE_PROVIDER_STATUS_FAIL";


export const GET_DRIVERS_LIST = "GET_DRIVERS_LIST";
export const GET_DRIVERS_LIST_SUCCESS = "GET_DRIVERS_LIST_SUCCESS";
export const GET_DRIVERS_LIST_FAIL = "GET_DRIVERS_LIST_FAIL";