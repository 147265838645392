// ================= BASIC_SETTING_DETAILS =================== //
export const BASIC_SETTING_DETAILS = "BASIC_SETTING_DETAILS";
export const BASIC_SETTING_DETAILS_SUCCESS = "BASIC_SETTING_DETAILS_SUCCESS";
export const BASIC_SETTING_DETAILS_FAIL = "BASIC_SETTING_DETAILS_FAIL";

// ================= GET_CONFIG_DETAILS =================== //
export const GET_CONFIG_DETAILS = "GET_CONFIG_DETAILS";
export const GET_CONFIG_DETAILS_SUCCESS = "GET_CONFIG_DETAILS_SUCCESS";
export const GET_CONFIG_DETAILS_FAIL = "GET_CONFIG_DETAILS_FAIL";

// // ================= BASIC_SETTING_UPDATE =================== //

export const BASIC_SETTING_UPDATE = "BASIC_SETTING_UPDATE";
export const BASIC_SETTING_UPDATE_SUCCESS = "BASIC_SETTING_UPDATE_SUCCESS";
export const BASIC_SETTING_UPDATE_FAIL = "BASIC_SETTING_UPDATE_FAIL";

// // ================= GET_CONFIG_UPDATE =================== //

export const GET_CONFIG_UPDATE = "GET_CONFIG_UPDATE";
export const GET_CONFIG_UPDATE_SUCCESS = "GET_CONFIG_UPDATE_SUCCESS";
export const GET_CONFIG_UPDATE_FAIL = "GET_CONFIG_UPDATE_FAIL";

// // ================= GET_MAIL_UPDATE =================== //

export const GET_MAIL_UPDATE = "GET_MAIL_UPDATE";
export const GET_MAIL_UPDATE_SUCCESS = "GET_MAIL_UPDATE_SUCCESS";
export const GET_MAIL_UPDATE_FAIL = "GET_MAIL_UPDATE_FAIL";

// // ================= PAYMENT_CONFIG_UPDATE =================== //

export const PAYMENT_CONFIG_UPDATE = "PAYMENT_CONFIG_UPDATE";
export const PAYMENT_CONFIG_UPDATE_SUCCESS = "PAYMENT_CONFIG_UPDATE_SUCCESS";
export const PAYMENT_CONFIG_UPDATE_FAIL = "PAYMENT_CONFIG_UPDATE_FAIL";

// // ================= ANDROID_VERSION_UPDATE =================== //

export const ANDROID_VERSION_UPDATE = "ANDROID_VERSION_UPDATE";
export const ANDROID_VERSION_UPDATE_SUCCESS = "ANDROID_VERSION_UPDATE_SUCCESS";
export const ANDROID_VERSION_UPDATE_FAIL = "ANDROID_VERSION_UPDATE_FAIL";

// // ================= ANDROID_APP_URL_UPDATE =================== //

export const ANDROID_APP_URL_UPDATE = "ANDROID_APP_URL_UPDATE";
export const ANDROID_APP_URL_UPDATE_SUCCESS = "ANDROID_APP_URL_UPDATE_SUCCESS";
export const ANDROID_APP_URL_UPDATE_FAIL = "ANDROID_APP_URL_UPDATE_FAIL";

// // ================= IOS_APP_URL_UPDATE =================== //

export const IOS_APP_URL_UPDATE = "IOS_APP_URL_UPDATE";
export const IOS_APP_URL_UPDATE_SUCCESS = "IOS_APP_URL_UPDATE_SUCCESS";
export const IOS_APP_URL_UPDATE_FAIL = "IOS_APP_URL_UPDATE_FAIL";

// // ================= GET_MAIL_TEMPLATES =================== //

export const GET_MAIL_TEMPLATES = "GET_MAIL_TEMPLATES";
export const GET_MAIL_TEMPLATES_SUCCESS = "GET_MAIL_TEMPLATES_SUCCESS";
export const GET_MAIL_TEMPLATES_FAIL = "GET_MAIL_TEMPLATES_FAIL";

// // ================= MAIL_TEMPLATES_UPDATE =================== //

export const MAIL_TEMPLATES_UPDATE = "MAIL_TEMPLATES_UPDATE";
export const MAIL_TEMPLATES_UPDATE_SUCCESS = "MAIL_TEMPLATES_UPDATE_SUCCESS";
export const MAIL_TEMPLATES_UPDATE_FAIL = "MAIL_TEMPLATES_UPDATE_FAIL";

// // ================= GET_SMS_TEMPLATES =================== //

export const GET_SMS_TEMPLATES = "GET_SMS_TEMPLATES";
export const GET_SMS_TEMPLATES_SUCCESS = "GET_SMS_TEMPLATES_SUCCESS";
export const GET_SMS_TEMPLATES_FAIL = "GET_SMS_TEMPLATES_FAIL";

// // ================= SMS_TEMPLATES_UPDATE =================== //

export const SMS_TEMPLATES_UPDATE = "SMS_TEMPLATES_UPDATE";
export const SMS_TEMPLATES_UPDATE_SUCCESS = "SMS_TEMPLATES_UPDATE_SUCCESS";
export const SMS_TEMPLATES_UPDATE_FAIL = "SMS_TEMPLATES_UPDATE_FAIL";
