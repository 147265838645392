import React from "react";
import AnnouncementBar from "../../../components/AnnouncementBar/AnnouncementBar";
import AddCustomIndex from "../../../components/SidetabPages/Customers/AddCustomIndex";

const AddOffer = () => {
  return (
    <>
      <AnnouncementBar />
      <AddCustomIndex />
    </>
  );
};
export default AddOffer;
