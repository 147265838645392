// ================= CONTENTPAGE_LIST =================== //

export const CONTENTPAGE_LIST = "CONTENTPAGE_LIST";
export const CONTENTPAGE_LIST_SUCCESS = "CONTENTPAGE_LIST_SUCCESS";
export const CONTENTPAGE_LIST_FAIL = "CONTENTPAGE_LIST_FAIL";

// ================= CONTENTPAGE_LIST_DETAILS =================== //
export const CONTENTPAGE_LIST_DETAILS = "CONTENTPAGE_LIST_DETAILS";
export const CONTENTPAGE_LIST_DETAILS_SUCCESS =
  "CONTENTPAGE_LIST_DETAILS_SUCCESS";
export const CONTENTPAGE_LIST_DETAILS_FAIL = "CONTENTPAGE_LIST_DETAILS_FAIL";

// ================= CONTENTPAGE_EDIT =================== //

export const CONTENTPAGE_EDIT = "CONTENTPAGE_EDIT";
export const CONTENTPAGE_EDIT_SUCCESS = "CONTENTPAGE_EDIT_SUCCESS";
export const CONTENTPAGE_EDIT_FAIL = "CONTENTPAGE_EDIT_FAIL";
