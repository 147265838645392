/////////////-----------------Login Action------------------///////////
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGIN_OTP = "LOGIN_OTP";
export const LOGIN_OTP_SUCCESS = "LOGIN_OTP_SUCCESS";
export const LOGIN_OTP_FAIL = "LOGIN_OTP_FAIL";

export const FORGOT_PASS_OTP = "FORGOT_PASS_OTP";
export const FORGOT_PASS_OTP_SUCCESS = "FORGOT_PASS_OTP_SUCCESS";
export const FORGOT_PASS_OTP_FAIL = "FORGOT_PASS_OTP_FAIL";

export const CHECK_FORGOT_OTP = "CHECK_FORGOT_OTP";
export const CHECK_FORGOT_OTP_SUCCESS = "CHECK_FORGOT_OTP_SUCCESS";
export const CHECK_FORGOT_OTP_FAIL = "CHECK_FORGOT_OTP_FAIL";

export const CHANGE_PASS = "CHANGE_PASS";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAIL = "CHANGE_PASS_FAIL";

export const CREATE_PASS = "CREATE_PASS";
export const CREATE_PASS_SUCCESS = "CREATE_PASS_SUCCESS";
export const CREATE_PASS_FAIL = "CREATE_PASS_FAIL";

export const USER_DATA = "USER_DATA";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDATE_USER_DATA_FAIL";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL";

export const POST_DOCUMENT = "POST_DOCUMENT";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAIL = "POST_DOCUMENT_FAIL";

export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const GET_DOCUMENT_BY_ID_SUCCESS = "GET_DOCUMENT_BY_ID_SUCCESS";
export const GET_DOCUMENT_BY_ID_FAIL = "GET_DOCUMENT_BY_ID_FAIL";

export const POST_CONTACT = "POST_CONTACT";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const POST_CONTACT_FAIL = "POST_CONTACT_FAIL";

export const POST_SUBSCRIBE = "POST_SUBSCRIBE";
export const POST_SUBSCRIBE_SUCCESS = "POST_SUBSCRIBE_SUCCESS";
export const POST_SUBSCRIBE_FAIL = "POST_SUBSCRIBE_FAIL";

export const GET_CONTACT_DETAIL = "GET_CONTACT_DETAIL";
export const GET_CONTACT_DETAIL_SUCCESS = "GET_CONTACT_DETAIL_SUCCESS";
export const GET_CONTACT_DETAIL_FAIL = "GET_CONTACT_DETAIL_FAIL";

export const POST_FACEBOOK = "POST_FACEBOOK";
export const POST_FACEBOOK_SUCCESS = "POST_FACEBOOK_SUCCESS";
export const POST_FACEBOOK_FAIL = "POST_FACEBOOK_FAIL";

export const POST_INSTAGRAM = "POST_INSTAGRAM";
export const POST_INSTAGRAM_SUCCESS = "POST_INSTAGRAM_SUCCESS";
export const POST_INSTAGRAM_FAIL = "POST_INSTAGRAM_FAIL";

export const POST_TWITTER = "POST_TWITTER";
export const POST_TWITTER_SUCCESS = "POST_TWITTER_SUCCESS";
export const POST_TWITTER_FAIL = "POST_TWITTER_FAIL";

export const POST_YOUTUBE = "POST_YOUTUBE";
export const POST_YOUTUBE_SUCCESS = "POST_YOUTUBE_SUCCESS";
export const POST_YOUTUBE_FAIL = "POST_YOUTUBE_FAIL";

export const POST_TIKTOK = "POST_TIKTOK";
export const POST_TIKTOK_SUCCESS = "POST_TIKTOK_SUCCESS";
export const POST_TIKTOK_FAIL = "POST_TIKTOK_FAIL";
